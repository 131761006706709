import { CircularProgress, Typography } from "@material-ui/core";
import React, { cloneElement, useEffect, useState } from "react";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import CheckIcon from "@material-ui/icons/Check";
import { motion } from "framer-motion";

// function LinearProgressWithLabel(props) {
//   return (
//     <Box display="flex" alignItems="center">
//       <Box width="100%" mr={1}>
//         <LinearProgress variant="determinate" {...props} />
//       </Box>
//       <Box minWidth={35}>
//         <Typography variant="body2" color="textSecondary">{`${Math.round(
//           props.value
//         )}%`}</Typography>
//       </Box>
//     </Box>
//   );
// }

const SubText = ({
  displayLock,
  openLock,
  headerText,
  text,
  custom,
  component,
  completed,
  id,
  length,
  animate = false,
  status = null,
  loading = false,
  icon,
}) => {
  const [showIcon, setShowIcon] = useState(false);

  useEffect(() => {
    if (completed) {
      setTimeout(() => {
        setShowIcon(true);
      }, 500);
    }
  }, [completed]);

  const animationProps = animate
    ? {
        initial: {
          x: -100,
          opacity: 0,
        },
        animate: { x: 0, opacity: 1 },
        transition: { duration: 1 },
      }
    : {};

  const fetchColor = () => {
    if (status === "success") return "green";
    if (status === "fail") return "red";
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
      key={id + text}
      className="w-3/4 pr-12"
    >
      <motion.div
        {...animationProps}
        style={{
          height: "10vh",
          backgroundColor:
            text || custom
              ? status
                ? fetchColor()
                : "#E87200"
              : "transparent",
          display: "grid",
          placeItems: "center",
        }}
        className="w-2/3"
      >
        <div className="flex items-center w-full">
          <div className="flex flex-col items-center px-4 w-11/12">
            {custom ? (
              component()
            ) : (
              <>
                <Typography
                  className="text-center text-white"
                  style={{ fontSize: "1.5vw" }}
                >
                  {headerText}
                </Typography>
                {typeof text === "string" ? (
                  <Typography
                    className="text-center text-white"
                    style={{ fontSize: "1.5vw" }}
                  >
                    {text}
                  </Typography>
                ) : (
                  cloneElement(text, {
                    className: "text-center text-white",
                    style: { fontSize: "1.5vw" },
                  })
                )}
              </>
            )}
          </div>
          {completed ? null : (
            <CircularProgress size={30} style={{ color: "white" }} />
          )}
        </div>
      </motion.div>

      <div>{showIcon ? icon ? icon : <CheckIcon color="primary" /> : null}</div>

      {displayLock ? (
        openLock ? (
          <LockOpenIcon style={{ fontSize: "4rem", color: "red" }} />
        ) : (
          <LockIcon style={{ fontSize: "4rem", color: "green" }} />
        )
      ) : (
        <div className="w-16" />
      )}
    </div>
  );
};

export default SubText;
