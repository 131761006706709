import { CircularProgress } from "@material-ui/core";
import React, { useState } from "react";
import axiosInstance from "../axiosInstance";
import BlueCard from "../Components/BlueCard";
import { TextField, MenuItem } from "@material-ui/core";

const RoboCall = () => {
  const [isLoading, setIsLoading] = useState(null);
  const [value, setValue] = useState("MS Teams");

  const generateCall = async (type) => {
    setIsLoading(type);
    try {
      const numbers = JSON.parse(localStorage.getItem("number") || null);
      const email = localStorage.getItem("email");
      await axiosInstance({
        method: "post",
        url: `/${type === "normal" ? "roboblock" : type}`,
        data: {
          from_user:
            type === "normal"
              ? numbers[email].normalcall
              : numbers[email][type][0],
              to_user: numbers[email].userextension[value.split(" ").join("")],
          ucselection: value,
        },
      });
    } catch (error) {}
    setIsLoading(null);
  };

  return (
    <>
      <div className="flex justify-between items-center mb-10">
        <div className="flex items-center">
          <p style={{ fontSize: "2vw" }}> Robocall simulation for &nbsp; </p>{" "}
          <TextField
            onChange={(e) => setValue(e.target.value)}
            value={value}
            select
          >
            <MenuItem value={"MS Teams"}>MS Teams</MenuItem>
            <MenuItem value={"Avaya UC"}>Avaya CC</MenuItem>
          </TextField>
        </div>
      </div>
      <div className="flex flex-col w-full items-start">
        <BlueCard
          style={{ cursor: "pointer" }}
          onClick={() => generateCall("roboflag")}
          className="my-2 rounded-xl"
          text={
            <div className="flex items-center">
              {isLoading === "roboflag" && (
                <CircularProgress size={20} style={{ color: "white" }} />
              )}
              <span>Generate robocall. Confidence Medium</span>
            </div>
          }
        />
        <BlueCard
          style={{ cursor: "pointer" }}
          onClick={() => generateCall("roboblock")}
          className="my-2 rounded-xl"
          text={
            <div className="flex items-center">
              {isLoading === "roboblock" && (
                <CircularProgress size={20} style={{ color: "white" }} />
              )}
              <span>Generate robocall. Confidence High</span>
            </div>
          }
        />
        <BlueCard
          style={{ cursor: "pointer" }}
          onClick={() => generateCall("normal")}
          className="my-2 rounded-xl"
          text={
            <div className="flex items-center">
              <span>
                {isLoading === "normal" && (
                  <CircularProgress size={20} style={{ color: "white" }} />
                )}
                Generate normal call
              </span>
            </div>
          }
        />
      </div>
    </>
  );
};

export default RoboCall;
