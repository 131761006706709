import { Button, Typography } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import axiosInstance from "../axiosInstance";
import BlueCard from "../Components/BlueCard";
import SubText from "../Components/SubText";

const defaultSubText = {
  "Scan Network Topology": null,
  "Locking an extension": null,
  "Brute Force": null,
  "Acquire control": null,
  "Acquiring multiple extensions": null,
};

const cardsData = {
  "Scan Network Topology": {
    length: 6,
    texts: [
      {
        completed: false,
        id: 0,
        custom: false,
        displayLock: false,
        openLock: false,
        headerText: "",
        text: "",
      },
      {
        animate: true,
        completed: false,
        id: 2,
        custom: false,
        displayLock: false,
        openLock: false,
        headerText: "Enumerating extension range",
        text: <strong>481000 - 481999</strong>,
      },
      {
        completed: false,
        id: 3,
        custom: false,
        displayLock: false,
        openLock: false,
        headerText: "Enumerating extension range",
        text: <strong>482000 - 482999</strong>,
      },
      {
        completed: false,
        id: 4,
        custom: false,
        displayLock: false,
        openLock: false,
        headerText: "Enumerating extension range",
        text: <strong>483000 - 483999</strong>,
      },
      {
        completed: false,
        id: 5,
        custom: false,
        displayLock: false,
        openLock: false,
        headerText: "Enumerating extension range",
        text: <strong>484000 - 484999</strong>,
      },
      {
        completed: true,
        id: 6,
        custom: false,
        displayLock: false,
        headerText: "Valid extension range",
        openLock: true,
        status: "fail",
        text: (
          <Typography>
            <strong>485000 - 485999</strong> identified
          </Typography>
        ),
      },
    ],
  },
  "Locking an extension": {
    length: 1,
    texts: [
      {
        animate: true,
        completed: true,
        id: 1,
        custom: false,
        displayLock: false,
        headerText: "",
        openLock: false,
        text: (
          <Typography>
            Locking on extension <strong>485954 </strong>
          </Typography>
        ),
      },
    ],
  },
  "Brute Force": {
    length: 3,
    texts: [
      {
        animate: true,
        completed: false,
        id: 1,
        custom: false,
        displayLock: false,
        headerText: "",
        openLock: false,
        text: (
          <Typography>
            Trying most common password for extension <strong> 485954</strong>
          </Typography>
        ),
      },
      {
        completed: false,
        id: 2,
        custom: true,
        displayLock: false,
        headerText: "",
        openLock: false,
        component: (count) => (
          <Typography className="text-center text-white font-bold">
            Trying different password combinations:{" "}
            <CountUp end={7533} duration={1.5} />
          </Typography>
        ),
      },
      {
        completed: true,
        id: 3,
        custom: false,
        displayLock: false,
        headerText: "",
        openLock: true,
        status: "fail",
        text: (
          <Typography>
            Password for extensions cracked in{" "}
            <strong>7,533 attempts/45 seconds</strong>
          </Typography>
        ),
      },
    ],
  },
  "Acquire control": {
    length: 1,
    texts: [
      {
        animate: true,
        completed: true,
        id: 1,
        custom: false,
        displayLock: false,
        headerText: "",
        openLock: true,
        text: (
          <Typography>
            Extension <strong>485954</strong> take over success (registered).
            Ready for further attack
          </Typography>
        ),
      },
    ],
  },
  "Acquiring multiple extensions": {
    length: 2,
    texts: [
      {
        animate: true,
        completed: false,
        id: 1,
        custom: false,
        displayLock: false,
        headerText: "",
        openLock: true,
        text: (
          <Typography>
            using global work load to crack password of extension
            <strong> 485500 - 485999</strong>
          </Typography>
        ),
      },
      {
        animate: false,
        completed: true,
        id: 2,
        custom: false,
        displayLock: false,
        headerText: "",
        openLock: true,
        status: "fail",
        text: (
          <Typography>
            <strong>75/100</strong> extensions cracked in <strong>185</strong>{" "}
            seconds
          </Typography>
        ),
      },
    ],
  },
};
const ExtensionTakeOver = () => {
  const [subText, setSubText] = useState(defaultSubText);
  const [trigger, setTrigger] = useState(false);

  const wait = (time) =>
    new Promise((resolve, reject) => setTimeout(() => resolve(), time));

  useEffect(() => {
    const numbers = JSON.parse(localStorage.getItem("number") || null);
    const email = localStorage.getItem("email");
    // const ourRequest = Axios.CancelToken.source();
    const source = axios.CancelToken.source();

    const launchEnumAttack = async () => {
      try {
      } catch (error) {}

      setSubText((prevText) => ({
        ...prevText,
        "Scan Network Topology": {
          animate: true,
          completed: false,
          id: 2,
          custom: false,
          displayLock: false,
          openLock: false,
          loading: true,
          headerText: "Enumerating extension range",
          text: <strong>303-1000 to 303-1999</strong>,
        },
      }));
      try {
        await axiosInstance({
          url: "/enum",
          cancelToken: source.token,
          method: "post",
          //cancelToken: ourRequest,
          data: {
            extension: numbers[email].userextension.AvayaUC,
            attempts: 15,
          },
        });
      } catch (error) {}

      setSubText((prevText) => ({
        ...prevText,
        "Scan Network Topology": {
          animate: false,
          completed: false,
          id: 3,
          custom: false,
          displayLock: false,
          openLock: false,
          loading: true,
          headerText: "Enumerating extension range",
          text: <strong>303-2000 to 303-2999</strong>,
        },
      }));
      try {
        await axiosInstance({
          cancelToken: source.token,
          url: "/enum",
          method: "post",
          //cancelToken: ourRequest,
          data: {
            extension: numbers[email].userextension.AvayaUC,
            attempts: 15,
          },
        });
      } catch (error) {}

      setSubText((prevText) => ({
        ...prevText,
        "Scan Network Topology": {
          animate: false,
          completed: false,
          id: 4,
          custom: false,
          displayLock: false,
          openLock: false,
          loading: true,
          headerText: "Enumerating extension range",
          text: <strong>303-3000 to 303-3999</strong>,
        },
      }));
      try {
        await axiosInstance({
          cancelToken: source.token,
          url: "/enum",
          method: "post",
          //cancelToken: ourRequest,
          data: {
            extension: numbers[email].userextension.AvayaUC,
            attempts: 15,
          },
        });
      } catch (error) {}
      setSubText((prevText) => ({
        ...prevText,
        "Scan Network Topology": {
          completed: true,
          id: 6,
          custom: false,
          displayLock: false,
          headerText: "",
          openLock: true,
          status: "fail",
          text: (
            <Typography>
              <span style={{ display: "block" }}>
                Detected valid extension range
              </span>
              <strong>303-4000</strong> to <strong>303-4999</strong>
            </Typography>
          ),
        },
      }));

      setSubText((prevText) => ({
        ...prevText,
        "Locking an extension": {
          animate: true,
          completed: true,
          id: 1,
          custom: false,
          displayLock: false,
          headerText: "",
          openLock: false,
          status: "fail",
          text: (
            <Typography>
              Selected extension <strong>303-4020 </strong> to attack
            </Typography>
          ),
        },
      }));

      await wait(1000);

      setSubText((prevText) => ({
        ...prevText,
        "Brute Force": {
          animate: true,
          completed: false,
          id: 1,
          custom: false,
          displayLock: false,
          headerText: "",
          openLock: false,
          text: (
            <Typography>
              Trying most common password for extension{" "}
              <strong> 303-4020</strong>
            </Typography>
          ),
        },
      }));

      try {
        await axiosInstance({
          cancelToken: source.token,
          url: "/bf",
          method: "post",
          //cancelToken: ourRequest,
          data: {
            extension: numbers[email].userextension.AvayaUC,
            attempts: 15,
            password: "567890",
          },
        });
      } catch (error) {}

      setSubText((prevText) => ({
        ...prevText,
        "Brute Force": {
          completed: false,
          id: 2,
          custom: true,
          displayLock: false,
          headerText: "",
          openLock: false,
          component: (count) => (
            <Typography
              className="text-center text-white font-bold"
              style={{ fontSize: "1.7vw" }}
            >
              Trying different password combinations:{" "}
              <strong>
                <CountUp end={7533} duration={1.5} />
              </strong>
            </Typography>
          ),
        },
      }));

      await wait(2000);
      setSubText((prevText) => ({
        ...prevText,
        "Brute Force": {
          completed: true,
          id: 3,
          custom: false,
          displayLock: false,
          headerText: "",
          openLock: true,
          status: "fail",
          text: (
            <Typography>
              Password for <strong>303-4020</strong> cracked in{" "}
              <strong>7,533 attempts</strong>
            </Typography>
          ),
        },
      }));

      setSubText((prevText) => ({
        ...prevText,
        "Acquire control": {
          animate: true,
          completed: true,
          id: 1,
          custom: false,
          displayLock: false,
          headerText: "",
          openLock: true,
          status: "fail",
          text: (
            <Typography>
              Extension <strong>303-4020</strong> take over success
              (registered).
            </Typography>
          ),
        },
      }));

      setTimeout(() => {
        setSubText((prevText) => ({
          ...prevText,
          "Acquiring multiple extensions": {
            animate: true,
            completed: false,
            id: 1,
            custom: false,
            displayLock: false,
            headerText: "",
            openLock: true,
            text: (
              <Typography>
                Using global work load to crack password of extensions
              </Typography>
            ),
          },
        }));
      }, 1000);

      try {
        await axiosInstance({
          cancelToken: source.token,
          url: "/sua",
          method: "post",
          //cancelToken: ourRequest,
          data: {
            extension: numbers[email].userextension.AvayaUC,
            password: "567890",
          },
        });
      } catch (error) {}

      try {
        await axiosInstance({
          cancelToken: source.token,
          url: "/sua",
          method: "post",
          //cancelToken: ourRequest,
          data: {
            extension: numbers[email].userextension.AvayaUC,
            password: "567890",
          },
        });
      } catch (error) {}

      try {
        await axiosInstance({
          cancelToken: source.token,
          url: "/sua",
          method: "post",
          //cancelToken: ourRequest,
          data: {
            extension: numbers[email].userextension.AvayaUC,
            password: "567890",
          },
        });
      } catch (error) {}

      setSubText((prevText) => ({
        ...prevText,
        "Acquiring multiple extensions": {
          animate: false,
          completed: true,
          id: 2,
          custom: false,
          displayLock: false,
          headerText: "",
          openLock: true,
          status: "fail",
          text: (
            <Typography>
              <strong>5</strong> extensions acquired successfully (registered)
            </Typography>
          ),
        },
      }));

      try {
        const numbers = JSON.parse(localStorage.getItem("number") || null);
        const email = localStorage.getItem("email");
        await axiosInstance({
          cancelToken: source.token,
          url:
            "https://3272bqiu05.execute-api.us-east-1.amazonaws.com/demo-us/v1/configuration/callrestriction",
          method: "post",
          data: {
            customer_id: "3ca1e010-25ea-11ed-b516-1341418d930c",
            regex_name: "BlockOutboundDemo",
            from_number_regex: `^${numbers[email].userextension.AvayaUC}.*`,
            to_number_regex: ".*",
            action: "block",
            type: "CustomerDeny",
            priority: "1",
            call_direction: "OUT",
            source_ip: "*",
            orig_remote_ip: "*",
          },
        });
      } catch (error) {}

      setTrigger(false);
    };

    trigger && launchEnumAttack();

    return () => source?.cancel();
  }, [trigger]);

  return (
    <div className="pl-12">
      <div className="flex justify-between items-center">
        <p style={{ fontSize: "2vw" }}>Remote Worker Breach</p>
        <Button
          variant="contained"
          size="large"
          color="primary"
          style={{ fontSize: "1vw" }}
          onClick={() => {
            if (trigger) {
              setTimeout(() => {
                setTrigger(false);
                setTimeout(() => {
                  setTrigger(true);
                }, 250);
              }, 250);
            } else setTrigger(true);
            setSubText(defaultSubText);
          }}
        >
          Start Attack
        </Button>
      </div>

      <div className="pt-8">
        {Object.entries(cardsData).map(([key, value], i) => (
          <div className="flex w-full items-center" key={key}>
            <BlueCard
              key={key}
              className="my-2 rounded-xl"
              count={i + 1}
              text={key}
            />
            {subText[key] && (
              <SubText {...subText[key]} length={cardsData[key].length} />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ExtensionTakeOver;
