import { Button, CircularProgress, TextField } from "@material-ui/core";
import React, { useState } from "react";
import axiosInstance from "../axiosInstance";
import BlueCard from "../Components/BlueCard";

const OutboundCall = () => {
  const [isLoadingCall, setIsLoadingCall] = useState(false);

  return (
    <div className="flex flex-col justify-start w-100 items-center ">
      <div className="w-full flex items-center">
        <BlueCard
          style={{ cursor: "pointer" }}
          // onClick={() => generateCall("roboblock")}
          className="my-2 rounded-xl mr-20"
          text={
            <div className="flex items-center">
              <span> Make outbound call</span>
            </div>
          }
        />
        <form
          className="flex items-center"
          onSubmit={async (e) => {
            e.preventDefault();
            setIsLoadingCall(true);

            try {
              const numbers = JSON.parse(
                localStorage.getItem("number") || null
              );
              const email = localStorage.getItem("email");

              await axiosInstance({
                method: "post",
                url: `/outboundcall`,
                data: {
                  from_user: numbers[email].userextension.AvayaUC,
                  to_user: Object.fromEntries(new FormData(e.target)).to_user,
                  ucselection: "AvayaUC",
                },
              });
            } catch (error) {}
            setIsLoadingCall(false);
          }}
        >
          <TextField
            variant="outlined"
            placeholder="To user"
            required
            type="number"
            name="to_user"
          />
          <Button
            style={{ marginLeft: "10px" }}
            type="submit"
            color="primary"
            variant="contained"
          >
            {isLoadingCall ? (
              <CircularProgress size={20} style={{ color: "white" }} />
            ) : (
              "  Submit"
            )}
          </Button>
        </form>
      </div>
      <div className="w-full flex">
        <BlueCard
          style={{ cursor: "pointer" }}
          onClick={async () => {
            const data = await axiosInstance({
              method: "get",
              url: `https://5fyei4fow8.execute-api.us-east-1.amazonaws.com/demo-us/callrestriction/3ca1e010-25ea-11ed-b516-1341418d930c`,
            });

            const numbers = JSON.parse(localStorage.getItem("number") || null);
            const email = localStorage.getItem("email");

            await axiosInstance({
              method: "delete",
              url: `https://3272bqiu05.execute-api.us-east-1.amazonaws.com/demo-us/v1/configuration/callrestriction`,
              data: {
                customer_id: "3ca1e010-25ea-11ed-b516-1341418d930c",
                regex_id:
                  data?.data?.data?.find(
                    (d) =>
                      d.from_number_regex ===
                      `^${numbers[email].userextension.AvayaUC}.*`
                  )?.regex_id || "",
              },
            });
          }}
          className="my-2 rounded-xl"
          text={
            <div className="flex items-center">
              <span>Reset outbound calling restrictions</span>
            </div>
          }
        />
      </div>
    </div>
  );
};

export default OutboundCall;
