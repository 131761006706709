import axios from "axios";

const axiosInstance = axios.create({
  baseURL: "https://attacks.sbclabs.assertion.cloud/attack",
  // baseURL: "https://jsonplaceholder.typicode.com/todos",
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});
//axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

export default axiosInstance;
