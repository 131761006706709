import { Button, Typography } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import axiosInstance from "../axiosInstance";
import BlueCard from "../Components/BlueCard";
import SubText from "../Components/SubText";
import ClearIcon from "@material-ui/icons/Clear";
//const source = axios.CancelToken.source();

const defaultSubText = {
  "Acquired extensions": null,
  "Attempting international calls from 300-4020": null,
  "Attempting international calls from 300-4055": null,
  "Attempting international calls from 300-4067": null,
  "Launch toll fraud attack": null,
};

const cardsData = {
  "Acquired extensions": {
    length: 2,
    texts: [
      {
        completed: false,
        id: 0,
        custom: false,
        displayLock: false,
        openLock: false,
        headerText: "",
        text: "",
      },
      {
        animate: true,
        completed: true,
        id: 2,
        custom: false,
        displayLock: false,
        openLock: false,
        headerText: "",
        text: "Taken over X number of extension",
      },
    ],
  },
  "Attempting international calls from 300-4020": {
    length: 5,
    texts: [
      {
        animate: true,
        completed: false,
        id: 1,
        custom: false,
        displayLock: false,
        openLock: false,
        headerText: "",
        text: "Dialing US",
      },
      {
        completed: false,
        id: 2,
        custom: false,
        displayLock: false,
        openLock: false,
        headerText: "",
        text: "Dialing UK",
      },
      {
        completed: false,
        id: 3,
        custom: false,
        displayLock: false,
        openLock: false,
        headerText: "",
        text: "Dialing Bulgaria",
      },
      {
        completed: false,
        id: 4,
        custom: false,
        displayLock: false,
        headerText: "",
        openLock: false,
        text: "Dialing UAE",
      },
      {
        completed: true,
        id: 5,
        custom: false,
        displayLock: false,
        headerText: "",
        openLock: false,
        text: "",
      },
    ],
  },
  "Attempting international calls from 300-4055": {
    length: 5,
    texts: [
      {
        animate: true,
        completed: false,
        id: 1,
        custom: false,
        displayLock: false,
        openLock: false,
        headerText: "",
        text: "Dialing US",
      },
      {
        completed: false,
        id: 2,
        custom: false,
        displayLock: false,
        openLock: false,
        headerText: "",
        text: "Dialing UK",
      },
      {
        completed: false,
        id: 3,
        custom: false,
        displayLock: false,
        openLock: false,
        headerText: "",
        text: "Dialing Bulgaria",
      },
      {
        completed: false,
        id: 4,
        custom: false,
        displayLock: false,
        headerText: "",
        openLock: false,
        text: "Dialing UAE",
      },
      {
        completed: true,
        id: 5,
        custom: false,
        displayLock: false,
        headerText: "",
        openLock: false,
        text: "",
      },
    ],
  },
  "Attempting international calls from 300-4067": {
    length: 5,
    texts: [
      {
        animate: true,
        completed: false,
        id: 1,
        custom: false,
        displayLock: false,
        openLock: false,
        headerText: "",
        text: "Dialing US",
      },
      {
        completed: false,
        id: 2,
        custom: false,
        displayLock: false,
        openLock: false,
        headerText: "",
        text: "Dialing UK",
      },
      {
        completed: false,
        id: 3,
        custom: false,
        displayLock: false,
        openLock: false,
        headerText: "",
        text: "Dialing Bulgaria",
      },
      {
        completed: false,
        id: 4,
        custom: false,
        displayLock: false,
        headerText: "",
        openLock: false,
        text: "Dialing UAE",
      },
      {
        completed: true,
        id: 5,
        custom: false,
        displayLock: false,
        headerText: "",
        openLock: false,
        text: "Premium rate Dial possible to UK and Bulgaria",
        status: "fail",
      },
    ],
  },
  "Launch toll fraud attack": {
    length: 1,
    texts: [
      {
        animate: true,
        completed: true,
        id: 1,
        custom: false,
        displayLock: false,
        headerText: "",
        openLock: false,
        text: "Dial out UK premium number",
        status: "fail",
      },
    ],
  },
};
const TollFraudAttack = () => {
  const [subText, setSubText] = useState(defaultSubText);
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    const source = axios.CancelToken.source();
    const numbers = JSON.parse(localStorage.getItem("number") || null);
    const email = localStorage.getItem("email");
    const launchEnumAttack = async () => {
      setSubText((prevText) => ({
        ...prevText,
        "Acquired extensions": {
          animate: true,
          completed: true,
          id: 2,
          custom: false,
          displayLock: false,
          openLock: false,
          headerText: "",
          text: (
            <Typography>
              <strong>5</strong> extensions acquired successfully
            </Typography>
          ),
          status: "fail",
        },
      }));
      setTimeout(() => {
        setSubText((prevText) => ({
          ...prevText,
          "Attempting international calls from 300-4020": {
            animate: true,
            completed: false,
            id: 1,
            custom: false,
            displayLock: false,
            openLock: false,
            headerText: "",
            text: "Dialing Estonia",
          },
        }));
      }, 500);

      try {
        await axiosInstance({
          cancelToken: source.token,
          url: "/tf",
          method: "post",
          data: {
            extension: numbers[email].userextension.AvayaUC,
            premium_rate: "+3729004567",
            password: "111111",
          },
        });
      } catch (error) {}

      setSubText((prevText) => ({
        ...prevText,
        "Attempting international calls from 300-4020": {
          completed: false,
          id: 2,
          custom: false,
          displayLock: false,
          openLock: false,
          headerText: "",
          text: "Dialing Belgium",
        },
      }));

      try {
        await axiosInstance({
          cancelToken: source.token,
          url: "/tf",
          method: "post",
          data: {
            extension: numbers[email].userextension.AvayaUC,
            premium_rate: "+329075637289",
            password: "111111",
          },
        });
      } catch (error) {}

      setSubText((prevText) => ({
        ...prevText,
        "Attempting international calls from 300-4020": {
          completed: false,
          id: 3,
          custom: false,
          displayLock: false,
          openLock: false,
          headerText: "",
          text: "Dialing US",
        },
      }));

      try {
        await axiosInstance({
          cancelToken: source.token,
          url: "/tf",
          method: "post",
          data: {
            extension: numbers[email].userextension.AvayaUC,
            premium_rate: "+19008764533",
            password: "111111",
          },
        });
      } catch (error) {}

      setSubText((prevText) => ({
        ...prevText,
        "Attempting international calls from 300-4020": {
          completed: false,
          id: 4,
          custom: false,
          displayLock: false,
          headerText: "",
          openLock: false,
          text: "Dialing Finland",
        },
      }));

      try {
        await axiosInstance({
          cancelToken: source.token,
          url: "/tf",
          method: "post",
          data: {
            extension: numbers[email].userextension.AvayaUC,
            premium_rate: "+35890700123",
            password: "111111",
          },
        });
      } catch (error) {}

      setSubText((prevText) => ({
        ...prevText,
        "Attempting international calls from 300-4020": {
          completed: true,
          id: 5,
          custom: false,
          displayLock: false,
          headerText: "",
          openLock: false,
          text: "International dialing blocked",
          icon: <ClearIcon color="secondary" />,
          status: "success",
        },
      }));

      setSubText((prevText) => ({
        ...prevText,
        "Attempting international calls from 300-4055": {
          animate: true,
          completed: false,
          id: 1,
          custom: false,
          displayLock: false,
          openLock: false,
          headerText: "",
          text: "Dialing Estonia",
        },
      }));

      try {
        await axiosInstance({
          cancelToken: source.token,
          url: "/tf",
          method: "post",
          data: {
            extension: "3034055",
            premium_rate: "+3729004567",
            password: "111111",
          },
        });
      } catch (error) {}

      setSubText((prevText) => ({
        ...prevText,
        "Attempting international calls from 300-4055": {
          completed: false,
          id: 2,
          custom: false,
          displayLock: false,
          openLock: false,
          headerText: "",
          text: "Dialing Belgium",
        },
      }));

      try {
        await axiosInstance({
          cancelToken: source.token,
          url: "/tf",
          method: "post",
          data: {
            extension: "3034055",
            premium_rate: "+329075637289",
            password: "111111",
          },
        });
      } catch (error) {}

      setSubText((prevText) => ({
        ...prevText,
        "Attempting international calls from 300-4055": {
          completed: false,
          id: 3,
          custom: false,
          displayLock: false,
          openLock: false,
          headerText: "",
          text: "Dialing US",
        },
      }));

      try {
        await axiosInstance({
          cancelToken: source.token,
          url: "/tf",
          method: "post",
          data: {
            extension: "3034055",
            premium_rate: "+19008764533",
            password: "111111",
          },
        });
      } catch (error) {}
      setSubText((prevText) => ({
        ...prevText,
        "Attempting international calls from 300-4055": {
          completed: false,
          id: 4,
          custom: false,
          displayLock: false,
          headerText: "",
          openLock: false,
          text: "Dialing Bulgaria",
        },
      }));
      try {
        await axiosInstance({
          cancelToken: source.token,
          url: "/tf",
          method: "post",
          data: {
            extension: "3034055",
            premium_rate: "+35890700123",
            password: "111111",
          },
        });
      } catch (error) {}

      setSubText((prevText) => ({
        ...prevText,
        "Attempting international calls from 300-4055": {
          completed: true,
          id: 5,
          custom: false,
          displayLock: false,
          headerText: "",
          openLock: false,
          text: "International dialing blocked",
          icon: <ClearIcon color="secondary" />,
          status: "success",
        },
      }));

      setSubText((prevText) => ({
        ...prevText,
        "Attempting international calls from 300-4067": {
          animate: true,
          completed: false,
          id: 1,
          custom: false,
          displayLock: false,
          openLock: false,
          headerText: "",
          text: "Dialing Estonia",
        },
      }));

      try {
        await axiosInstance({
          cancelToken: source.token,
          url: "/tf",
          method: "post",
          data: {
            extension: "3034067",
            premium_rate: "+3729004567",
            password: "111111",
          },
        });
      } catch (error) {}

      setSubText((prevText) => ({
        ...prevText,
        "Attempting international calls from 300-4067": {
          completed: false,
          id: 2,
          custom: false,
          displayLock: false,
          openLock: false,
          headerText: "",
          text: "Dialing Belgium",
        },
      }));

      try {
        await axiosInstance({
          cancelToken: source.token,
          url: "/tf",
          method: "post",
          data: {
            extension: "3034067",
            premium_rate: "+329075637289",
            password: "111111",
          },
        });
      } catch (error) {}

      setSubText((prevText) => ({
        ...prevText,
        "Attempting international calls from 300-4067": {
          completed: false,
          id: 3,
          custom: false,
          displayLock: false,
          openLock: false,
          headerText: "",
          text: "Dialing US",
        },
      }));

      try {
        await axiosInstance({
          cancelToken: source.token,
          url: "/tf",
          method: "post",
          data: {
            extension: "3034067",
            premium_rate: "+19008764533",
            password: "567890",
          },
        });
      } catch (error) {}
      setSubText((prevText) => ({
        ...prevText,
        "Attempting international calls from 300-4067": {
          completed: false,
          id: 4,
          custom: false,
          displayLock: false,
          headerText: "",
          openLock: false,
          text: "Dialing Finland",
        },
      }));
      try {
        await axiosInstance({
          cancelToken: source.token,
          url: "/tf",
          method: "post",
          data: {
            extension: "3034067",
            premium_rate: "+35890700123",
            password: "567890",
          },
        });
      } catch (error) {}

      setSubText((prevText) => ({
        ...prevText,
        "Attempting international calls from 300-4067": {
          completed: true,
          id: 5,
          custom: false,
          displayLock: false,
          headerText: "",
          openLock: false,
          text: "International calling successful",
          status: "fail",
        },
      }));

      setSubText((prevText) => ({
        ...prevText,
        "Launch toll fraud attack": {
          animate: true,
          completed: false,
          id: 1,
          custom: false,
          displayLock: false,
          headerText: "",
          openLock: false,
          text: "Dialing premium rate numbers – 1 of 10​",
          status: "fail",
        },
      }));
      try {
        await axiosInstance({
          cancelToken: source.token,
          url: "/tf",
          method: "post",
          data: {
            extension: "3034067",
            premium_rate: "+19008764533",
            password: "567890",
          },
        });
      } catch (error) {}
      setSubText((prevText) => ({
        ...prevText,
        "Launch toll fraud attack": {
          animate: false,
          completed: false,
          id: 1,
          custom: false,
          displayLock: false,
          headerText: "",
          openLock: false,
          text: "Dialing premium rate numbers – 2 of 10​",
          status: "fail",
        },
      }));
      try {
        await axiosInstance({
          cancelToken: source.token,
          url: "/tf",
          method: "post",
          data: {
            extension: "3034067",
            premium_rate: "+35890700123",
            password: "567890",
          },
        });
      } catch (error) {}
      setSubText((prevText) => ({
        ...prevText,
        "Launch toll fraud attack": {
          animate: false,
          completed: false,
          id: 1,
          custom: false,
          displayLock: false,
          headerText: "",
          openLock: false,
          text: "Dialing premium rate numbers – 3 of 10​",
          status: "fail",
        },
      }));
      try {
        await axiosInstance({
          cancelToken: source.token,
          url: "/tf",
          method: "post",
          data: {
            extension: "3034067",
            premium_rate: "+19008764533",
            password: "567890",
          },
        });
      } catch (error) {}
      setSubText((prevText) => ({
        ...prevText,
        "Launch toll fraud attack": {
          animate: false,
          completed: false,
          id: 1,
          custom: false,
          displayLock: false,
          headerText: "",
          openLock: false,
          text: "Dialing premium rate numbers – 4 of 10​",
          status: "fail",
        },
      }));
      try {
        await axiosInstance({
          cancelToken: source.token,
          url: "/tf",
          method: "post",
          data: {
            extension: "3034067",
            premium_rate: "+35890700123",
            password: "567890",
          },
        });
      } catch (error) {}
      setSubText((prevText) => ({
        ...prevText,
        "Launch toll fraud attack": {
          animate: false,
          completed: false,
          id: 1,
          custom: false,
          displayLock: false,
          headerText: "",
          openLock: false,
          text: "Dialing premium rate numbers – 5 of 10​",
          status: "fail",
        },
      }));
      try {
        await axiosInstance({
          cancelToken: source.token,
          url: "/tf",
          method: "post",
          data: {
            extension: "3034067",
            premium_rate: "+19008764533",
            password: "567890",
          },
        });
      } catch (error) {}
      setSubText((prevText) => ({
        ...prevText,
        "Launch toll fraud attack": {
          animate: false,
          completed: false,
          id: 1,
          custom: false,
          displayLock: false,
          headerText: "",
          openLock: false,
          text: "Dialing premium rate numbers – 6 of 10​",
          status: "fail",
        },
      }));
      try {
        await axiosInstance({
          cancelToken: source.token,
          url: "/tf",
          method: "post",
          data: {
            extension: "3034067",
            premium_rate: "+35890700123",
            password: "567890",
          },
        });
      } catch (error) {}
      setSubText((prevText) => ({
        ...prevText,
        "Launch toll fraud attack": {
          animate: false,
          completed: false,
          id: 1,
          custom: false,
          displayLock: false,
          headerText: "",
          openLock: false,
          text: "Dialing premium rate numbers – 7 of 10​",
          status: "fail",
        },
      }));
      try {
        await axiosInstance({
          cancelToken: source.token,
          url: "/tf",
          method: "post",
          data: {
            extension: "3034067",
            premium_rate: "+19008764533",
            password: "567890",
          },
        });
      } catch (error) {}
      setSubText((prevText) => ({
        ...prevText,
        "Launch toll fraud attack": {
          animate: false,
          completed: false,
          id: 1,
          custom: false,
          displayLock: false,
          headerText: "",
          openLock: false,
          text: "Dialing premium rate numbers – 8 of 10​",
          status: "fail",
        },
      }));
      try {
        await axiosInstance({
          cancelToken: source.token,
          url: "/tf",
          method: "post",
          data: {
            extension: "3034067",
            premium_rate: "+35890700123",
            password: "567890",
          },
        });
      } catch (error) {}
      setSubText((prevText) => ({
        ...prevText,
        "Launch toll fraud attack": {
          animate: false,
          completed: false,
          id: 1,
          custom: false,
          displayLock: false,
          headerText: "",
          openLock: false,
          text: "Dialing premium rate numbers – 9 of 10​",
          status: "fail",
        },
      }));
      try {
        await axiosInstance({
          cancelToken: source.token,
          url: "/tf",
          method: "post",
          data: {
            extension: "3034067",
            premium_rate: "+19008764533",
            password: "567890",
          },
        });
      } catch (error) {}
      setSubText((prevText) => ({
        ...prevText,
        "Launch toll fraud attack": {
          animate: false,
          completed: true,
          id: 1,
          custom: false,
          displayLock: false,
          headerText: "",
          openLock: false,
          text: "Dialing premium rate numbers – 10 of 10​",
          status: "fail",
        },
      }));
      try {
        await axiosInstance({
          cancelToken: source.token,
          url: "/tf",
          method: "post",
          data: {
            extension: "3034067",
            premium_rate: "+35890700123",
            password: "567890",
          },
        });
      } catch (error) {}
      setTrigger(false);
    };
    trigger && launchEnumAttack();

    return () => source?.cancel();
  }, [trigger]);

  return (
    <div className="pl-12">
      <div className="flex justify-between items-center">
        <p style={{ fontSize: "2vw" }}>Toll Fraud Attack</p>
        <Button
          variant="contained"
          color="primary"
          size="large"
          style={{ fontSize: "1vw" }}
          onClick={() => {
            if (trigger) {
              setTimeout(() => {
                setTrigger(false);
                setTimeout(() => {
                  setTrigger(true);
                }, 250);
              }, 250);
            } else setTrigger(true);
            setSubText(defaultSubText);
          }}
        >
          Start Attack
        </Button>
      </div>

      <div className="pt-8">
        {Object.entries(cardsData).map(([key, value], i) => (
          <div className="flex w-full items-center" key={key}>
            <BlueCard
              fontSize="1.3vw"
              key={key}
              className="my-2 rounded-xl"
              count={i + 1}
              text={key}
            />
            {subText[key] && (
              <SubText {...subText[key]} length={cardsData[key].length} />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TollFraudAttack;
