import { Button, IconButton, Typography } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

const Simulation = ({ children }) => {
  const hightLightProps = {
    variant: "outlined",
    style: { textTransform: "none", borderColor: "white" },
  };

  const pathname = window.location.pathname;

  return (
    <div className=" flex flex-col w-full h-full justify-center items-center">
      <div
        className="w-full flex justify-between items-center  pr-10"
        style={{ backgroundColor: "#5470be", height: "9vh" }}
      >
        <strong style={{ fontSize: "1.9vw", color: "white" }} className="pl-12">
          Simulator
        </strong>
        <div className="flex justify-end items-center h-full">
          <Button
            component={Link}
            to="/robocall"
            style={{ textTransform: "none" }}
            {...(pathname === "/robocall" ? hightLightProps : {})}
          >
            <Typography className="text-white" style={{ fontSize: "1vw" }}>
              Robocall
            </Typography>
          </Button>
          <Button
            component={Link}
            to="/spam-call"
            style={{ textTransform: "none" }}
            {...(pathname === "/spam-call" ? hightLightProps : {})}
          >
            <Typography className="text-white" style={{ fontSize: "1vw" }}>
              Scam call
            </Typography>
          </Button>
          <Button
            component={Link}
            to="/outbound-call"
            style={{ textTransform: "none" }}
            {...(pathname === "/outbound-call" ? hightLightProps : {})}
          >
            <Typography className="text-white" style={{ fontSize: "1vw" }}>
              Outbound call
            </Typography>
          </Button>
          <Button
            component={Link}
            to="/identity-assurance"
            style={{ textTransform: "none" }}
            {...(pathname === "/identity-assurance" ? hightLightProps : {})}
          >
            <Typography className="text-white" style={{ fontSize: "1vw" }}>
              Identity assurance
            </Typography>
          </Button>
          <Button
            component={Link}
            to="/remote-worker-breach"
            style={{ textTransform: "none" }}
            {...(pathname === "/remote-worker-breach" ? hightLightProps : {})}
          >
            <Typography className="text-white" style={{ fontSize: "1vw" }}>
              Remote Worker Breach
            </Typography>
          </Button>
          <Button
            component={Link}
            to="/toll-fraud-attack"
            style={{ textTransform: "none" }}
            {...(pathname === "/toll-fraud-attack" ? hightLightProps : {})}
          >
            <Typography className="text-white" style={{ fontSize: "1vw" }}>
              Toll Fraud
            </Typography>
          </Button>
          <IconButton
            onClick={() => {
              localStorage.clear();
              window.location.href = "/login";
            }}
          >
            <ExitToAppIcon style={{ color: "white" }} />
          </IconButton>
        </div>
      </div>
      <div className="pt-8 w-4/5">{children}</div>
    </div>
  );
};

export default Simulation;
