import {
  Button,
  CircularProgress,
  MenuItem,
  TextField,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import React, { useState } from "react";
import axiosInstance from "../axiosInstance";

const baseURL = "https://attacks.sbclabs.assertion.cloud";

const IdenityAssurance = () => {
  const [isLoadingCall, setIsLoadingCall] = useState(false);
  const [isLoadingAssignCustomer, setIsLoadingAssignCustomer] = useState(false);
  const [isLoadingTriggerConsent, setIsLoadingTriggerConsent] = useState(false);
  const [isLoadingPredictive, setIsLoadingPredictive] = useState(false);
  const [telcoBranding, setTelcoBranding] = useState(false);
  const [consentCallReason, setConsentCallReason] = useState("Your RM, Doe, will be calling you to discuss an onboarding offer specially curated for you as you are a premium customer of our bank. Kindly let us know when can we call...");
  const [customer_number, setCustomerNumber] = useState("");

  return (
    <div style={{ width: "100%", display: "grid", placeItems: "center" }}>
      <div
        className="flex flex-col justify-start w-100 items-center "
        style={{ width: "60%" }}
      >
        <div className="w-full flex  flex-col items-center w-100 justify-center">
          <form
            style={{ position: 'relative' }}
            className="flex flex-col w-full items-center mb-12"
            onSubmit={async (e) => {
              e.preventDefault();
              setIsLoadingTriggerConsent(true);

              try {
                const email = localStorage.getItem("email");
                await axiosInstance({
                  method: "post",
                  url: `agentflow/trigger-consent-flow`,
                  baseURL,
                  data: {
                    user_email: email,
                    ...Object.fromEntries(new FormData(e.target)),
                  },
                });
              } catch (error) { }
              setIsLoadingTriggerConsent(false);
            }}
          >
            <div className="mb-4 text-2xl">Consent section</div>
            <div className="flex w-full justify-between w-full items-center">
              <TextField
                variant="outlined"
                placeholder="Customer number"
                required
                style={{ width: "180px" }}
                type="number"
                name="customer_number"
              />
              {/* <TextField
              select
              variant="outlined"
              placeholder="Call reason"
              style={{ margin: "0 2rem", width: "200px" }}
              required
              name="mode"
              defaultValue={"preview"}
            >
              <MenuItem value="preview">Preview mode</MenuItem>
              <MenuItem value="predictive">Predictive mode</MenuItem>
            </TextField> */}
              <TextField
                required
                spellcheck="false"
                name="call_reason"
                style={{ width: "355px", padding: 0 }}
                variant="outlined"
                onChange={(e) => {
                  if (e.target.value.length <= 180) {
                    setConsentCallReason(e.target.value)
                  }
                }}
                value={consentCallReason}
                multiline
                maxRows={20}
              />
              <span style={{ position: 'absolute', bottom: -20, right: 160, color: 'grey', fontSize: '14px' }}>{consentCallReason.length} / 180</span>
              <Button
                style={{ width: "150px" }}
                type="submit"
                color="primary"
                variant="contained"
              >
                {isLoadingTriggerConsent ? (
                  <CircularProgress size={20} style={{ color: "white" }} />
                ) : (
                  "Trigger consent flow"
                )}
              </Button>
            </div>
          </form>
          <form
            className="flex flex-col w-full items-center mb-12"
            style={{ position: 'relative' }}
            onSubmit={async (e) => {
              e.preventDefault();
              setIsLoadingPredictive(true);

              try {
                const email = localStorage.getItem("email");
                await axiosInstance({
                  method: "post",
                  url: `agentflow/trigger-predictive-flow`,
                  baseURL,
                  data: {
                    user: email,
                    ...Object.fromEntries(new FormData(e.target)),
                    telcobranding: telcoBranding
                  },
                });
              } catch (error) { }
              setIsLoadingPredictive(false);
            }}
          >
            <div className="mb-4 text-2xl">Predictive mode section</div>
            <div className="flex justify-between w-full items-center">
              <TextField
                variant="outlined"
                placeholder="Customer number"
                required
                type="number"
                name="customer_number"
              />
              <TextField
                variant="outlined"
                placeholder="Call reason"
                required
                type="text"
                name="call_reason"
              />

              <Button
                style={{ width: "200px" }}
                type="submit"
                color="primary"
                variant="contained"
              >
                {isLoadingPredictive ? (
                  <CircularProgress size={20} style={{ color: "white" }} />
                ) : (
                  "Trigger predictive flow"
                )}
              </Button>

            </div>
            <FormControlLabel style={{ position: 'absolute', right: -163, top: 58 }}
              control={
                <Checkbox
                  name="telcobranding"
                  color="primary"
                  checked={telcoBranding}
                  onChange={(e) => setTelcoBranding(e.target.checked)}
                />}
              label="TelcoBranding" name="telcobranding" />
          </form>
          <form
            className="flex flex-col w-full items-center mb-12"
            onSubmit={async (e) => {
              e.preventDefault();
              setIsLoadingAssignCustomer(true);

              try {
                const email = localStorage.getItem("email");
                await axiosInstance({
                  method: "post",
                  url: `agentflow/add-customer-mapping`,
                  baseURL,
                  data: {
                    user_email: email,
                    mode: "preview",
                    ...Object.fromEntries(new FormData(e.target)),
                  },
                });
              } catch (error) { }
              setIsLoadingAssignCustomer(false);
            }}
          >
            <div className="mb-4 text-2xl">Preview mode section</div>
            <div className="flex justify-between w-full items-center">
              <TextField
                variant="outlined"
                placeholder="Customer number"
                required
                type="number"
                name="customer_number"
              />

              <Button
                style={{ marginLeft: "10px", width: "200px" }}
                type="submit"
                color="primary"
                variant="contained"
              >
                {isLoadingAssignCustomer ? (
                  <CircularProgress size={20} style={{ color: "white" }} />
                ) : (
                  "Trigger preview flow"
                )}
              </Button>
            </div>
          </form>
          <div
            style={{
              width: "100%",
              height: "2px",
              background: "grey",
              marginBottom: "1rem",
            }}
          />

          <div className="w-full flex justify-between mb-4">
            <div>
              <Button
                color="primary"
                variant="contained"
                onClick={async () => {
                  try {
                    const email = localStorage.getItem("email");
                    const response = await axiosInstance({
                      method: "post",
                      url: `agentflow/fetch-user-assignment`,
                      baseURL,
                      data: {
                        user_email: email,
                      },
                    });
                    setCustomerNumber(response.data.customer_number);
                  } catch (error) { }
                  setIsLoadingAssignCustomer(false);
                }}
              >
                Get customer association
              </Button>
              <span className="ml-4">{customer_number}</span>
            </div>
            <Button
              color="primary"
              variant="contained"
              onClick={async () => {
                try {
                  const email = localStorage.getItem("email");
                  await axiosInstance({
                    method: "post",
                    url: `agentflow/remove-customer-mapping`,
                    baseURL,
                    data: {
                      user_email: email,
                    },
                  });
                } catch (error) { }
                setIsLoadingAssignCustomer(false);
              }}
            >
              Remove customer association
            </Button>
          </div>
          {/* 
        <div className="mb-20" style={{ alignSelf: "flex-start" }}>
          {customer_number}
        </div> */}

          {/* <form
          className="flex  items-center"
          onSubmit={async (e) => {
            e.preventDefault();
            setIsLoadingCall(true);

            try {
              // const numbers = JSON.parse(
              //   localStorage.getItem("number") || null
              // );
              // const email = localStorage.getItem("email");

              await axiosInstance({
                method: "post",
                url: `/identityassuranceagentcalling`,
                data: {
                  from_user: "+14694936080",
                  to_user: Object.fromEntries(new FormData(e.target)).to_user,
                  call_reason: Object.fromEntries(new FormData(e.target))
                    .call_reason,
                },
              });
            } catch (error) {}
            setIsLoadingCall(false);
          }}
        >
          <TextField
            variant="outlined"
            placeholder="To user"
            required
            type="number"
            name="to_user"
          />
          <TextField
            variant="outlined"
            placeholder="Call reason"
            style={{ margin: "0 2rem" }}
            required
            name="call_reason"
          />
          <Button
            style={{ marginLeft: "10px" }}
            type="submit"
            color="primary"
            variant="contained"
          >
            {isLoadingCall ? (
              <CircularProgress size={20} style={{ color: "white" }} />
            ) : (
              "Make outbound call"
            )}
          </Button>
        </form> */}
        </div>
      </div>
    </div>
  );
};

export default IdenityAssurance;
