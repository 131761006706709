import React, { useState } from "react";
import { Grid, TextField, Button, CircularProgress } from "@material-ui/core";
import axiosInstance from "../axiosInstance";

const Login = () => {
  const [isLoading, setIsLoading] = useState(false);
  const onSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    const email = document.getElementById("email").value;
    try {
      const data = await axiosInstance({
        method: "post",
        url: "/userprofile",
        data: {
          user_email_id: email,
        },
      });

      if (typeof data.data === "string") {
        alert("Entered email address is not valid");
        setIsLoading(false);
        return;
      }
      localStorage.setItem("number", JSON.stringify(data.data));
      localStorage.setItem("email", email);
      window.location.href = "/remote-worker-breach";
    } catch (error) {}
    setIsLoading(false);
  };

  if (localStorage.getItem("email")) {
    window.location.href = "/remote-worker-breach";
  }

  return (
    <form
      onSubmit={onSubmit}
      className="grid place-items-center"
      style={{ width: "100%", height: "100vh", background: "#1a2038" }}
    >
      <div className="w-1/2 ">
        <Grid container direction="row">
          <Grid item xs={5} className="rounded-l-xl px-12 py-20 bg-gray-200">
            <img src="/assertion.png" alt="assertion" />
          </Grid>
          <Grid
            item
            xs={7}
            className="flex-col bg-white rounded-r-xl justify-between flex items-center px-10 py-10"
          >
            <TextField
              required
              type="email"
              id="email"
              placeholder="Email"
              className="w-full"
              variant="outlined"
            />
            <Button
              type="submit"
              startIcon={
                <>
                  {isLoading ? (
                    <CircularProgress size={15} style={{ color: "white" }} />
                  ) : (
                    <></>
                  )}
                </>
              }
              variant="contained"
              color="primary"
            >
              Sign in
            </Button>
          </Grid>
        </Grid>
      </div>
    </form>
  );
};

export default Login;
