import { Chip } from "@material-ui/core";
import React from "react";

const BlueCard = ({
  count,
  text,
  style = {},
  fontSize = "1.5vw",
  className = "",
  ...rest
}) => {
  return (
    <div
      style={{
        backgroundColor: "#094780",
        height: "14vh",
        ...style,
      }}
      className={`${className} w-1/4`}
      {...rest}
    >
      {count && (
        <Chip
          label={count}
          className="absolute"
          style={{ backgroundColor: "#bec1a6", fontSize: "0.8vw" }}
        />
      )}
      <div className="h-full flex justify-center items-center">
        <div
          className="text-center text-white font-bold px-4"
          style={{ fontSize }}
        >
          {text}
        </div>
      </div>
    </div>
  );
};

export default BlueCard;
