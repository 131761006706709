const PageNotFound = () => {
  const email = localStorage.getItem("email");
  if (email) {
    window.location.href = "/remote-worker-breach";
  } else {
    window.location.href = "/login";
  }
};

export default PageNotFound;
